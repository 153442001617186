import React from 'react';
import './FooterNavigation.scss';

const FooterNavigation = () => (
  <footer className="footer-navigation">
    <ul>
      <li><a href="/events">Events</a></li>
      <li><a href="/podcasts">Podcasts</a></li>
      <li><a href="/special-reports">Special Reports</a></li>
      {/* Add other footer links here */}
    </ul>
  </footer>
);

export default FooterNavigation;
