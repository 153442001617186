import React from 'react';
import { Carousel } from 'react-bootstrap';
import ContentCard from './ContentCard';
import './FeaturedSection.scss';

const FeaturedSection = ({ contents }) => (
  <Carousel>
    {contents.map(content => (
      <Carousel.Item key={content.id}>
        <ContentCard content={content} />
      </Carousel.Item>
    ))}
  </Carousel>
);

export default FeaturedSection;
