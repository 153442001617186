import React from 'react';
import './Sidebar.scss';

const Sidebar = ({ mostRead }) => (
  <aside className="sidebar">
    <h3>Most Read</h3>
    <ul>
      {mostRead.map(item => (
        <li key={item.id}>
          <a href={`/content/${item.content_type}/${item.id}`}>{item.title}</a>
        </li>
      ))}
    </ul>
  </aside>
);

export default Sidebar;
