import { axiosInstance } from '../api/axiosConfig';
import { addToCart, clearCart } from '../features/cart/cartSlice';
import { addToFavourites } from '../features/favourites/favouritesSlice';

let isSyncing = false; // Add a flag to prevent repeated calls

// Sync the cart and favourites with the server
export const syncCartAndFavourites = async (userId, dispatch) => {
  if (isSyncing) return; // Exit if a sync is already in progress
  isSyncing = true; // Set flag to indicate sync has started

  const localCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const favouritesItems = JSON.parse(localStorage.getItem('favouritesItems')) || [];

  try {
    // Check if local cart is empty; if so, fetch server cart directly
    if (localCartItems.length === 0) {
      const response = await axiosInstance.get(`/orders/getCart/${userId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      const serverCartItems = response.data.items;

      // Update localStorage with the server’s final cart state
      localStorage.setItem(
        'cartItems',
        JSON.stringify(
          serverCartItems.map((item) => ({
            id: item.product.id,
            quantity: item.quantity,
          }))
        )
      );

      // Clear Redux cart state and add server items to Redux
      dispatch(clearCart());
      serverCartItems.forEach((item) =>
        dispatch(
          addToCart({
            id: item.product.id,
            name: item.product.name,
            price: item.product.price,
            thumbnail_image: item.product.thumbnail_image,
            quantity: item.quantity,
          })
        )
      );
    } else {
      // If local cart has items, proceed with merge and sync logic
      const mappedCartItems = localCartItems.map((item) => ({
        product: item.id,
        quantity: item.quantity || 1,
      }));

      const response = await axiosInstance.post(
        '/orders/syncCart/',
        { userId, cartItems: mappedCartItems },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const serverCartItems = response.data.items;

      // Update localStorage with the server’s final cart state
      localStorage.setItem(
        'cartItems',
        JSON.stringify(
          serverCartItems.map((item) => ({
            id: item.product.id,
            quantity: item.quantity,
          }))
        )
      );

      // Clear Redux cart state and add merged items to Redux
      dispatch(clearCart());
      serverCartItems.forEach((item) =>
        dispatch(
          addToCart({
            id: item.product.id,
            name: item.product.name,
            price: item.product.price,
            thumbnail_image: item.product.thumbnail_image,
            quantity: item.quantity,
          })
        )
      );
    }

    // Sync Favourites
    const favResponse = await axiosInstance.post(
      '/orders/syncFavourites/',
      { userId, favouritesItems },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      }
    );
    favResponse.data?.items?.forEach((item) => dispatch(addToFavourites(item)));
  } catch (error) {
    console.error('Error syncing cart or favourites:', error.response?.data || error);
  } finally {
    isSyncing = false; // Reset flag to allow future syncs
  }
};

// New function to delete cart items from the backend
export const deleteFromCart = async (userId, productId, dispatch) => {
  try {
    const response = await axiosInstance.delete(`/orders/removeCartItem/${userId}/${productId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
    } else {
      console.error('Failed to remove item from server cart:', response.data);
    }
  } catch (error) {
    console.error('Error deleting cart item:', error.response?.data || error);
  }
};