import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import productReducer from './features/product/productSlice';
import favouritesReducer from './features/favourites/favouritesSlice';
import cartReducer from './features/cart/cartSlice';
import orderReducer from './features/user/orderSlice'; // Ensure this path is correct

const preloadedState = {
  user: {
    userInfo: null,
    status: 'idle',
    error: null,
    token: localStorage.getItem('token'), // Retrieve token from local storage
  },
};

const store = configureStore({
  reducer: {
    user: userReducer,
    products: productReducer,
    cart: cartReducer,
    favourites: favouritesReducer,
    order: orderReducer, // Add the order reducer
  },
  preloadedState,
});

export default store;