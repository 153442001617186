import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import './ContentCard.scss';

const ContentCard = ({ content, type = 'article' }) => {  // Default to 'article'

  let urlPath;
  switch (type) {
    case 'white_paper':
      urlPath = `/content/white-paper/${content.id}`;
      break;
    case 'case_study':
      urlPath = `/content/case-study/${content.id}`;
      break;
    case 'ebook':
      urlPath = `/content/ebook/${content.id}`;
      break;
    case 'research_report':
      urlPath = `/content/research-report/${content.id}`;
      break;
    case 'thought_leadership':
      urlPath = `/content/thought-leadership/${content.id}`;
      break;
    case 'technical_manual':
      urlPath = `/content/technical-manual/${content.id}`;
      break;
    case 'newsletter':
      urlPath = `/content/newsletter/${content.id}`;
      break;
    case 'presentation':
      urlPath = `/content/presentation/${content.id}`;
      break;
    case 'press_release':
      urlPath = `/content/press-release/${content.id}`;
      break;
    case 'video':
      urlPath = `/content/video/${content.id}`;
      break;
    case 'article':
      urlPath = `/content/article/${content.id}`;
      break;
    case 'guide':
      urlPath = `/content/guide/${content.id}`;
      break;
    default:
      urlPath = `/content/${type}/${content.id}`;
      console.warn("Unexpected content type or missing type:", { type, content });
      break;
  }

  const buttonLabel = type === 'video' ? 'Watch now' : type === 'ebook' || type === 'white_paper' ? 'Download' : 'Read more';

  return (
    <Card className="content-card">
      {content.cover_image && <Card.Img variant="top" src={content.cover_image} alt={content.title} />}
      <Card.Body>
        <Card.Title className="card-title">{content.title}</Card.Title>
        <Card.Text className="card-summary">{content.summary}</Card.Text>

        {type === 'ebook' || type === 'white_paper' ? (
          <a href={content.file_attachment} download className="btn btn-secondary">
            {buttonLabel}
          </a>
        ) : (
          <Link to={urlPath} className="btn btn-primary">
            {buttonLabel}
          </Link>
        )}
      </Card.Body>
    </Card>
  );
};

export default ContentCard;
