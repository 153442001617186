import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { userInfo, token } = useSelector((state) => state.user);

  // Check if the token exists, if not, redirect to login
  if (!token) {
    return <Navigate to="/login" />;
  }

  // If userInfo is not yet loaded, show a loading spinner or message
  if (!userInfo) {
    return <div>Loading...</div>; // Show a loader while fetching user data
  }

  // If both token and userInfo are valid, render the protected component
  return children;
};

export default ProtectedRoute;