import React from 'react';
import './Welcome.scss'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

const Welcome = () => {
  return (
    <div className="welcome-message">
      <h2>Welcome to our catalogue!</h2>
      <p>Use the filters to search for products <FontAwesomeIcon icon={faFilter} /></p>
    </div>
  );
};

export default Welcome;