import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './FilterSidebar.scss';
import { meilisearchInstance } from '../../api/axiosConfig';

const FilterSidebar = ({ onChange }) => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [filters, setFilters] = useState({
    query: '',
    category: '',
    priceRange: [0, 100],
    brand: '',
    productType: '',
  });
  const [collapsed, setCollapsed] = useState(false);

  // Fetch available filters for categories, brands, and product types
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        // Fetch filters
        const response = await meilisearchInstance.post('/indexes/products_v5/search', {
          facets: ['category.name', 'brand', 'product_type'],
          limit: 0,
        });

        if (response.data.facetDistribution) {
          const fetchedCategories = Object.keys(response.data.facetDistribution['category.name']).map((name) => ({
            name,
            count: response.data.facetDistribution['category.name'][name],
          }));
          const fetchedBrands = Object.keys(response.data.facetDistribution.brand).map((name) => ({
            name,
            count: response.data.facetDistribution.brand[name],
          }));
          const fetchedProductTypes = Object.keys(response.data.facetDistribution.product_type).map((name) => ({
            name,
            count: response.data.facetDistribution.product_type[name],
          }));

          setCategories(fetchedCategories);
          setBrands(fetchedBrands);
          setProductTypes(fetchedProductTypes);
        } else {
          console.error('Facet distribution not found in response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchFilters();
  }, []);

  // Handle changes to any filter
  const handleChange = (name, value) => {
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
    console.log(`Filter updated - ${name}:`, value);
    onChange(newFilters); // Automatically trigger onChange when filter changes
  };

  const handleSliderChange = (name) => (value) => {
    handleChange(name, value);
  };

  return (
    <div className={`filter-sidebar ${collapsed ? 'collapsed' : ''}`}>
      <button
        className="collapse-button btn btn-secondary mb-3"
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? 'Expand Filters' : 'Collapse Filters'}
      </button>
      <h2 className="h4 mb-4">Filters</h2>
      {!collapsed && (
        <>
          <div className="filter-group mb-4">
            <label htmlFor="query" className="form-label">Search</label>
            <input
              type="text"
              name="query"
              id="query"
              value={filters.query}
              onChange={(e) => handleChange('query', e.target.value)}
              className="form-control"
            />
          </div>
          <div className="filter-group mb-4">
            <label htmlFor="category" className="form-label">Category</label>
            <select
              name="category"
              id="category"
              value={filters.category}
              onChange={(e) => handleChange('category', e.target.value)}
              className="form-select"
            >
              <option value="">All</option>
              {categories.map((category) => (
                <option key={category.name} value={category.name}>
                  {category.name} ({category.count})
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group mb-4">
            <label className="form-label">Price Range (£)</label>
            <Slider
              range
              min={0}
              max={100}
              value={filters.priceRange}
              onChange={handleSliderChange('priceRange')}
              className="form-range"
            />
            <span>{`£${filters.priceRange[0]} - £${filters.priceRange[1]}`}</span>
          </div>
          <div className="filter-group mb-4">
            <label htmlFor="brand" className="form-label">Brand</label>
            <select
              name="brand"
              id="brand"
              value={filters.brand}
              onChange={(e) => handleChange('brand', e.target.value)}
              className="form-select"
            >
              <option value="">All</option>
              {brands.map((brand) => (
                <option key={brand.name} value={brand.name}>
                  {brand.name} ({brand.count})
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group mb-4">
            <label htmlFor="productType" className="form-label">Product Type</label>
            <select
              name="productType"
              id="productType"
              value={filters.productType}
              onChange={(e) => handleChange('productType', e.target.value)}
              className="form-select"
            >
              <option value="">All</option>
              {productTypes.map((type) => (
                <option key={type.name} value={type.name}>
                  {type.name} ({type.count})
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
};

export default FilterSidebar;