import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Navbar, Nav, Form, FormControl, Button, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../features/user/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faHeart, faCashRegister, faSearch } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo.svg';
import './NavBar.scss';

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user) || {};
  const cart = useSelector(state => state.cart.items);
  const favourites = useSelector(state => state.favourites.items);

  const handleLogout = () => {
    dispatch(logoutUser()).then(() => {
      navigate('/login');
    });
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="custom-navbar">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="RuothStore" className="navbar-logo" />
        </Navbar.Brand>
        <div className="navbar-icons-container">
          <Nav.Link as={Link} to="/cart">
            <FontAwesomeIcon icon={faShoppingCart} />
            <span className="badge badge-pill badge-light">{cart.length}</span>
          </Nav.Link>
          <Nav.Link as={Link} to="/favourites">
            <FontAwesomeIcon icon={faHeart} />
            <span className="badge badge-pill badge-light">{favourites.length}</span>
          </Nav.Link>
          <Nav.Link as={Link} to="/checkout">
            <FontAwesomeIcon icon={faCashRegister} />
          </Nav.Link>
        </div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <div className="navbar-top d-flex justify-content-between w-100">
            {userInfo && (
              <div className="navbar-user d-flex align-items-center">
                <span>Welcome, {userInfo.username}</span>
                <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
                <Button variant="link" className="nav-link" data-cy="logout" onClick={handleLogout}>Logout</Button>
              </div>
            )}
            {!userInfo && (
              <div className="navbar-auth-links d-flex align-items-center">
                <Nav.Link as={Link} to="/login">Login</Nav.Link>
                <Nav.Link as={Link} to="/register">Register</Nav.Link>
              </div>
            )}
          </div>
          <div className="navbar-search-container d-flex justify-content-center w-100 my-2">
            <Form className="navbar-search d-flex">
              <FormControl type="text" placeholder="Search Products" className="search-input" />
              <Button variant="primary" className="search-button"><FontAwesomeIcon icon={faSearch} /></Button>
            </Form>
          </div>
          <Nav className="nav-tabs justify-content-center w-100">
            <Nav.Item className="nav-item">
              <Nav.Link as={Link} to="/catalogue">Catalogue</Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item">
              <Nav.Link as={Link} to="/content-hub">Content Hub</Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item">
              <Nav.Link as={Link} to="/customer-support">Customer Support</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;