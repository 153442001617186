// SubmitReviewModal.js
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SubmitReview from './SubmitReview';

const SubmitReviewModal = ({ productId, onReviewSubmitted }) => {
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Submit Review
            </Button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Submit Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SubmitReview
                        productId={productId}
                        onReviewSubmitted={onReviewSubmitted}
                        closeModal={handleClose}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SubmitReviewModal;