import { axiosInstance } from '../../api/axiosConfig';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import './SearchComponent.scss';

const SearchComponent = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  // Access the token from the Redux store to check if the user is authenticated
  const token = useSelector((state) => state.user.token);
  const isAuthenticated = !!token;

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.get(`/support/support-tickets/?search=${query}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setResults(response.data);
    } catch (error) {
      console.error('Error searching tickets:', error);
    }
  };

  return (
    <Container className="search-component mb-4">
      <h2 className="text-center mb-4">Search Support Tickets</h2>

      {!isAuthenticated && (
        <Alert variant="warning">
          Please <a href="/login">log in</a> to search support tickets.
        </Alert>
      )}

      {isAuthenticated && (
        <>
          <Form onSubmit={handleSearch}>
            <Form.Group controlId="formSearch">
              <Form.Control 
                type="text" 
                value={query} 
                onChange={(e) => setQuery(e.target.value)} 
                placeholder="Search..." 
                className="search-input"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 search-btn">Search</Button>
          </Form>

          <ul className="search-results list-unstyled mt-4">
            {results.map(ticket => (
              <li key={ticket.id} className="mb-3 pb-3 border-bottom search-result-item">
                <h3>{ticket.subject}</h3>
                <p dangerouslySetInnerHTML={{ __html: ticket.description }}></p>
              </li>
            ))}
          </ul>
        </>
      )}
    </Container>
  );
};

export default SearchComponent;