import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: JSON.parse(localStorage.getItem('favouritesItems')) || []
};

const favouritesSlice = createSlice({
    name: 'favourites',
    initialState,
    reducers: {
        addToFavourites: (state, action) => {
            state.items.push(action.payload);
            localStorage.setItem('favouritesItems', JSON.stringify(state.items));
        },
        removeFromFavourites: (state, action) => {
            state.items = state.items.filter(item => item.id !== action.payload.id);
            localStorage.setItem('favouritesItems', JSON.stringify(state.items));
        }
    }
});

export const { addToFavourites, removeFromFavourites } = favouritesSlice.actions;
export default favouritesSlice.reducer;