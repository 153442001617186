import React from 'react';
import './OrderConfirmation.scss';

const OrderConfirmation = ({ order, handleOrderCompletion }) => {
    if (!order) {
        return <div>Loading...</div>;
    }

    const { id, total_price, items } = order;

    return (
        <div className="order-confirmation">
            <h1>Checkout</h1>
            <h2>Order Confirmation</h2>
            <p>Thank you for your order!</p>
            <h3>Order Summary</h3>
            <p><strong>Order ID:</strong> {id}</p>
            <p><strong>Total Price:</strong> £{parseFloat(total_price).toFixed(2)}</p>
            <div>
                {items && items.map((item, index) => (
                    <div key={index}>
                        <p><strong>Product:</strong> {item.product.name}</p>
                        <p><strong>Price:</strong> £{parseFloat(item.price).toFixed(2)}</p>
                        <p><strong>Quantity:</strong> {item.quantity}</p>
                        <p><strong>Total:</strong> £{parseFloat(item.total_price).toFixed(2)}</p>
                    </div>
                ))}
            </div>
            <button onClick={handleOrderCompletion} className="finish-button">Finish</button>
        </div>
    );
};

export default OrderConfirmation;