// src/components/homePage/ProductHighlights.js
import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { axiosInstance } from '../../api/axiosConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductHighlights.scss';

const ProductHighlights = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axiosInstance.get('/products/highlights/');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching product highlights:', error);
      }
    };
    fetchProducts();
  }, []);

  return (
    <div className="product-highlights-container">
      {products.length ? products.map(product => (
        <Card key={product.id} className="product-card">
          <Card.Img variant="top" src={product.thumbnail_image} className="product-image" />
          <Card.Body className="card-body">
            <Card.Title className="card-title">{product.name}</Card.Title>
            <Card.Text className="card-text">£{product.price}</Card.Text>
            <a href={`/product/${product.id}`} className="btn btn-primary">View Details</a>
          </Card.Body>
        </Card>
      )) : <p>Error fetching product highlights</p>}
    </div>
  );
};

export default ProductHighlights;