import React, { useEffect, useState, useRef } from 'react';
import { Form, Button, Container, Alert, Spinner, Card, Collapse } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { axiosInstance } from '../../api/axiosConfig';
import './SupportTickets.scss';

const SupportTickets = ({ isAuthenticated }) => {
  const [tickets, setTickets] = useState([]);
  const [newTicket, setNewTicket] = useState({
    subject: '',
    description: '',
    priority: 'medium'
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [newTicketId, setNewTicketId] = useState(null);
  const newTicketRef = useRef(null);

  useEffect(() => {
    axiosInstance.get('/support/support-tickets/')
      .then(response => setTickets(response.data))
      .catch(error => console.error
        .error('Error fetching tickets:', error));
      }, []);
    
      const handleChange = e => {
        setNewTicket({ ...newTicket, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = e => {
        e.preventDefault();
    
        if (!isAuthenticated) {
          alert("You need to be logged in to create a ticket.");
          return;
        }
    
        setLoading(true);
        setSuccessMessage('');
    
        axiosInstance.post('/support/support-tickets/', {
          subject: newTicket.subject,
          description: newTicket.description,
          priority: newTicket.priority
        })
        .then(response => {
          setTickets([...tickets, response.data]);
          setNewTicketId(response.data.id);
          setNewTicket({ subject: '', description: '', priority: 'medium' });
          setSuccessMessage('Your ticket has been successfully submitted!');
          setLoading(false);
        })
        .catch(error => {
          if (error.response) {
            console.error('Error creating ticket:', error.response.data);
          } else {
            console.error('Error creating ticket:', error.message);
          }
          setLoading(false);
        });
      };
    
      const toggleTicketDetails = (id) => {
        setTickets(tickets.map(ticket =>
          ticket.id === id ? { ...ticket, isOpen: !ticket.isOpen } : ticket
        ));
      };
    
      const closeTicket = (id) => {
        axiosInstance.put(`/support/support-tickets/${id}/`, { status: 'closed' })
          .then(response => {
            setTickets(tickets.map(ticket =>
              ticket.id === id ? { ...ticket, status: 'closed' } : ticket
            ));
          })
          .catch(error => console.error('Error closing ticket:', error));
      };
    
      return (
        <Container className="support-tickets mb-4">
          <h2 className="text-center mb-4">Support Tickets</h2>
    
          {!isAuthenticated && (
            <Alert variant="warning">
              Please <a href="/login">log in</a> to create a support ticket.
            </Alert>
          )}
    
          {isAuthenticated && (
            <>
              {successMessage && (
                <Alert variant="success" dismissible onClose={() => setSuccessMessage('')}>
                  {successMessage}
                </Alert>
              )}
    
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formSubject">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control 
                    type="text" 
                    name="subject" 
                    value={newTicket.subject} 
                    onChange={handleChange} 
                    disabled={loading}
                    className="ticket-input"
                  />
                </Form.Group>
                <Form.Group controlId="formDescription">
                  <Form.Label>Description</Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={newTicket.description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setNewTicket({ ...newTicket, description: data });
                    }}
                  />
                </Form.Group>
                <Form.Group controlId="formPriority">
                  <Form.Label>Priority</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="priority" 
                    value={newTicket.priority} 
                    onChange={handleChange}
                    disabled={loading}
                    className="ticket-input"
                  >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 submit-btn" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : 'Create Ticket'}
                </Button>
              </Form>
            </>
          )}
    
          <h2 className="mt-5 text-center">Existing Tickets</h2>
          <div className="ticket-list">
            {tickets.map(ticket => (
              <Card key={ticket.id} className="mb-3 ticket-card">
                <Card.Header onClick={() => toggleTicketDetails(ticket.id)} className="ticket-header">
                  <h3 className="ticket-title">{ticket.subject}</h3>
                  <p><strong>Priority:</strong> {ticket.priority}</p>
                  <p><strong>Status:</strong> {ticket.status}</p>
                </Card.Header>
                <Collapse in={ticket.isOpen}>
                  <Card.Body className="ticket-body">
                    <p dangerouslySetInnerHTML={{ __html: ticket.description }}></p>
                    <p><strong>Sentiment Score:</strong> {ticket.sentiment}</p>
                    <p><strong>Category:</strong> {ticket.category}</p>
                    {ticket.status !== 'closed' && (
                      <Button variant="danger" onClick={() => closeTicket(ticket.id)}>
                        Close Ticket
                      </Button>
                    )}
                  </Card.Body>
                </Collapse>
              </Card>
            ))}
          </div>
        </Container>
      );
    };
    
    export default SupportTickets;