import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../features/user/userSlice';
import './UserSettings.scss';

const UserSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userInfo);
  const [formData, setFormData] = useState({
    address: user?.address || '',
    phone_number: user?.phone_number || '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        address: user.address || '',
        phone_number: user.phone_number || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user?.id) {
      dispatch(updateUser({ ...formData, id: user.id }));
    }
  };

  return (
    <div className="user-settings section">
      <h2>Settings</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Address:</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Phone Number:</label>
          <input
            type="text"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary">Update</button>
      </form>
    </div>
  );
};

export default UserSettings;