import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { initiateReturn } from '../../features/user/orderSlice';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ReturnForm.scss';

const ReturnForm = () => {
    const { orderId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [reason, setReason] = useState('');
    const [additionalDetails, setAdditionalDetails] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);  // State for success message

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);
        try {
            await dispatch(initiateReturn({ orderId, reason, additionalDetails }));
            setLoading(false);
            setSuccess(true);  // Set success to true
            setTimeout(() => {
                navigate('/dashboard/orders');  // Update the path to match your routes
            }, 2000);  // Redirect after 2 seconds
        } catch (err) {
            setError('Failed to initiate return. Please try again.');
            setLoading(false);
        }
    };

    return (
        <div className="return-form">
            <div className="card shadow-sm">
                <div className="card-header text-center">
                    <h2>Return Order</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="reason" className="form-label">Reason for Return</label>
                            <select
                                id="reason"
                                className="form-control"
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select a reason</option>
                                <option value="Damaged item">Damaged item</option>
                                <option value="Wrong item received">Wrong item received</option>
                                <option value="Item not as described">Item not as described</option>
                                <option value="Changed my mind">Changed my mind</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        {reason === 'Other' && (
                            <div className="mb-3">
                                <label htmlFor="additionalDetails" className="form-label">Additional Details</label>
                                <textarea
                                    id="additionalDetails"
                                    className="form-control"
                                    value={additionalDetails}
                                    onChange={(e) => setAdditionalDetails(e.target.value)}
                                ></textarea>
                            </div>
                        )}
                        {error && <div className="alert alert-danger">{error}</div>}
                        {success && <div className="alert alert-success">Return initiated successfully! Redirecting to order history...</div>}
                        <div className="text-center">
                            <button type="submit" className="btn btn-primary btn-lg" disabled={loading}>
                                {loading ? 'Submitting...' : 'Submit Return'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ReturnForm;