import React, { useState } from 'react';
import { submitReview } from '../../api/reviewApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SubmitReview.scss';

const SubmitReview = ({ productId, onReviewSubmitted, closeModal }) => {
    const [rating, setRating] = useState(1);
    const [comment, setComment] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await submitReview({ product: productId, rating, comment });
            setMessage('Thank you for your review!');
            setTimeout(() => {
                setMessage('');
                onReviewSubmitted();
                closeModal(); // Close the modal after submission
            }, 3000);
        } catch (error) {
            let errorMessage = 'Failed to submit review';
            if (error.response && error.response.data) {
                if (typeof error.response.data === 'string') {
                    errorMessage = error.response.data;
                } else if (error.response.data.detail) {
                    errorMessage = error.response.data.detail;
                } else if (error.response.data.errors) {
                    errorMessage = error.response.data.errors.join(', ');
                } else if (error.response.data.messages) {
                    errorMessage = error.response.data.messages.map(msg => msg.message).join(', ');
                } else {
                    errorMessage = JSON.stringify(error.response.data);
                }
            }
            setMessage(errorMessage);
        }
    };

    return (
        <div className="submit-review-modal">
            <form onSubmit={handleSubmit} className="submit-review-form">
                <div className="form-group">
                    <label htmlFor="rating">Rating:</label>
                    <select
                        id="rating"
                        className="form-control"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                    >
                        {[1, 2, 3, 4, 5].map(num => (
                            <option key={num} value={num}>{num}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="comment">Comment:</label>
                    <textarea
                        id="comment"
                        className="form-control"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </div>
                <button type="submit" className="btn btn-primary">Submit Review</button>
                {message && <p className="review-message">{message}</p>}
            </form>
        </div>
    );
};

export default SubmitReview;