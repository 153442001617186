// src/components/homePage/NewsletterSignup.js
import React, { useState } from 'react';
import { axiosInstance } from '../../api/axiosConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NewsletterSignup.scss';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/newsletter/newsletter/', { email });
      setMessage('Thank you for signing up!');
    } catch (error) {
      setMessage('Failed to sign up. Please try again.');
    }
  };

  return (
    <div className="newsletter-signup section bg-light">
      <h3>Sign Up for Our Newsletter</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Sign Up</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default NewsletterSignup;