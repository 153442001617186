import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet
import FilterSidebar from '../components/productCatalogue/FilterSidebar';
import Pagination from '../components/productCatalogue/Pagination';
import ProductCard from '../components/productCatalogue/ProductCard';
import Welcome from '../components/productCatalogue/Welcome';
import './ProductCatalogue.scss';
import { meilisearchInstance } from '../api/axiosConfig';

const ProductCatalogueWithFilters = () => {
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    query: '',
    category: '',
    priceRange: [0, 100],
    brand: '',
    productType: '',
    ordering: '',
  });
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch products based on filters
  const fetchProducts = async (currentFilters, page = 1) => {
    try {
      console.log('Fetching products with filters:', currentFilters);

      // Build MeiliSearch query
      const searchQuery = {
        q: currentFilters.query || '', // This is the search term
        filter: [], // Initialize filter array
        offset: (page - 1) * 10,
        limit: 10, // Pagination
      };

      // Apply category filter
      if (currentFilters.category && currentFilters.category !== 'All') {
        searchQuery.filter.push(`category.name = "${currentFilters.category}"`);
      }

      // Apply price range filter
      if (currentFilters.priceRange) {
        searchQuery.filter.push(
          `price >= ${currentFilters.priceRange[0]} AND price <= ${currentFilters.priceRange[1]}`
        );
      }

      // Apply brand filter
      if (currentFilters.brand && currentFilters.brand !== 'All') {
        searchQuery.filter.push(`brand = "${currentFilters.brand}"`);
      }

      // Apply product type filter
      if (currentFilters.productType && currentFilters.productType !== 'All') {
        searchQuery.filter.push(`product_type = "${currentFilters.productType}"`);
      }

      console.log('MeiliSearch query:', JSON.stringify(searchQuery, null, 2));

      // Send the search request to MeiliSearch
      const response = await meilisearchInstance.post(
        '/indexes/products_v5/search',
        searchQuery
      );

      console.log('MeiliSearch full response:', response);
      if (response.data.hits) {
        console.log('Products fetched successfully:', response.data.hits);
        setProducts(response.data.hits);
        setTotalPages(Math.ceil(response.data.estimatedTotalHits / 10));
      } else {
        console.error('No hits in the response:', response.data);
        setProducts([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setInitialLoad(false);
    }
  };

  useEffect(() => {
    if (initialLoad) {
      console.log('Initial load:', initialLoad);
      fetchProducts(filters);
    }
  }, [initialLoad, filters]);

  const handleFilterChange = (newFilters) => {
    console.log('Filters changed:', newFilters);
    setFilters(newFilters);
    fetchProducts(newFilters, 1); // Reset to first page when filters change
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    console.log('Page changed to:', page);
    setCurrentPage(page);
    fetchProducts(filters, page);
  };

  return (
    <div className="container-fluid product-catalogue">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Product Catalogue | Ruoth</title>
        <meta
          name="description"
          content="Explore Ruoth's extensive range of high-quality culinary tools designed to empower creativity and precision in the kitchen. Find the perfect tools for your culinary needs."
        />
        <meta
          name="keywords"
          content="Ruoth products, culinary tools, kitchen innovation, professional kitchen equipment, cake scrapers, sieves, cooking utensils, product catalogue"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Product Catalogue | Ruoth" />
        <meta
          property="og:description"
          content="Browse Ruoth’s premium selection of physical and digital culinary tools. Elevate your kitchen creativity with our innovative products."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/path-to-catalogue-image.jpg"
        />
        <meta property="og:url" content="https://www.ruothstore.com/catalogue" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Product Catalogue | Ruoth" />
        <meta
          name="twitter:description"
          content="Discover Ruoth’s high-quality culinary tools designed for professionals and enthusiasts alike."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/path-to-catalogue-image.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.ruothstore.com/catalogue" />
      </Helmet>

      <div className="row">
        <div className="col-lg-3">
          <FilterSidebar onChange={handleFilterChange} />
        </div>
        <div className="col-lg-9">
          <div className="product-list">
            <Welcome />
            {!initialLoad && products && products.length > 0 ? (
              <div className="row">
                {products.map((product) => (
                  <div key={product.id} className="col-md-4 mb-4">
                    <ProductCard product={product} />
                  </div>
                ))}
              </div>
            ) : (
              !initialLoad && <p>No products found</p>
            )}
            {!initialLoad && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCatalogueWithFilters;