// src/components/homePage/Footer.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faYoutube,
  faInstagram,
  faTiktok,
  faPinterest,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faPaypal,
  faStripe,
} from '@fortawesome/free-brands-svg-icons';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer bg-light py-4">
      <div className="container">
        <div className="footer-sections">
          <div className="section">
            <h5>Company</h5>
            <ul className="list-unstyled">
              <li><a href="/about-us">About Us</a></li>
              <li><a href="/careers">Careers</a></li>
              <li><a href="/blog">Blog</a></li>
              <li><a href="/press">Press</a></li>
            </ul>
          </div>
          <div className="section">
            <h5>Customer Service</h5>
            <ul className="list-unstyled">
              <li><a href="/help-center">Help Center</a></li>
              <li><a href="/returns">Returns</a></li>
              <li><a href="/shipping">Shipping</a></li>
              <li><a href="/contact-us">Contact Us</a></li>
            </ul>
          </div>
          <div className="section">
            <h5>Legal</h5>
            <ul className="list-unstyled">
              <li><a href="/terms">Terms & Conditions</a></li>
              <li><a href="/privacy">Privacy Policy</a></li>
              <li><a href="/cookie">Cookie Policy</a></li>
            </ul>
          </div>
          <div className="section">
            <h5>Follow Us</h5>
            <div className="social-icons">
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faTiktok} />
              </a>
              <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faPinterest} />
              </a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </div>
          </div>
        </div>
        <div className="payment-icons">
          <FontAwesomeIcon icon={faCcVisa} className="payment-icon" />
          <FontAwesomeIcon icon={faCcMastercard} className="payment-icon" />
          <FontAwesomeIcon icon={faCcAmex} className="payment-icon" />
          <FontAwesomeIcon icon={faCcDiscover} className="payment-icon" />
          <FontAwesomeIcon icon={faPaypal} className="payment-icon" />
          <FontAwesomeIcon icon={faStripe} className="payment-icon" />
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Ruoth. All rights reserved. L.O.J Enterprise Ltd</p>
      </div>
    </footer>
  );
};

export default Footer;