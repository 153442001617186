import React from 'react';
import NavBar from './homePage/NavBar';
import Footer from './homePage/Footer';
import AnalyticsTracker from './AnalyticsTracker'; // Import the AnalyticsTracker component

const Layout = ({ children }) => {
  return (
    <div>
      <AnalyticsTracker /> {/* Add the AnalyticsTracker at the top */}
      <NavBar />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;