import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet'; // Import React Helmet
import { registerUser, clearError } from '../features/user/userSlice';
import { useNavigate, Link } from 'react-router-dom';
import { syncCartAndFavourites } from '../api/sync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import './RegisterComponent.scss';

const RegisterComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, userInfo, error } = useSelector((state) => state.user);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [gdprAccepted, setGdprAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (status === 'succeeded' && userInfo) {
      setSuccessMessage('Registration successful! Redirecting to checkout...');
      syncCartAndFavourites(userInfo.id, dispatch);
      setTimeout(() => navigate('/checkout'), 2000);
    }
  }, [status, userInfo, navigate, dispatch]);

  useEffect(() => {
    if (error) {
      if (error.username) {
        setErrorMessage(`Username error: ${error.username.join(', ')}`);
      } else if (error.email) {
        setErrorMessage(`Email error: ${error.email.join(', ')}`);
      } else {
        setErrorMessage('An unknown error occurred.');
      }
    } else {
      setErrorMessage('');
    }
  }, [error]);

  const handleRegister = (e) => {
    e.preventDefault();
    if (!termsAccepted || !gdprAccepted) {
      setErrorMessage('You must accept the terms and conditions and GDPR compliance to register.');
      return;
    }
    setErrorMessage('');
    setSuccessMessage('');
    dispatch(clearError());
    dispatch(
      registerUser({
        email,
        username,
        password,
        terms_accepted: termsAccepted,
        gdpr_accepted: gdprAccepted,
      })
    );
  };

  return (
    <div className="register-page container">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Register | Ruoth</title>
        <meta
          name="description"
          content="Create a new account on Ruoth to access exclusive features and offers."
        />
        <meta
          name="keywords"
          content="Ruoth, register, sign up, create account, user registration"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Register | Ruoth" />
        <meta
          property="og:description"
          content="Join Ruoth today and enjoy exclusive benefits and features."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/assets/images/register-banner.jpg"
        />
        <meta property="og:url" content="https://www.ruothstore.com/register" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Register | Ruoth" />
        <meta
          name="twitter:description"
          content="Create your Ruoth account now to unlock exclusive features."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/assets/images/register-banner.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.ruothstore.com/register" />
      </Helmet>

      <div className="register-card">
        <h2 className="text-center">Register</h2>
        {successMessage && <div className="alert alert-success">{successMessage}</div>}
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              id="terms"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
            <label htmlFor="terms">
              I agree to the <Link to="/terms">Terms and Conditions</Link>
            </label>
          </div>
          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              id="gdpr"
              checked={gdprAccepted}
              onChange={(e) => setGdprAccepted(e.target.checked)}
            />
            <label htmlFor="gdpr">
              I agree to the <Link to="/gdpr">GDPR Compliance</Link>
            </label>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={status === 'loading'}
          >
            {status === 'loading' ? 'Registering...' : 'Register'}
          </button>
        </form>
        <div className="social-login">
          <button type="button" className="btn btn-google">
            <FontAwesomeIcon icon={faGoogle} /> Register with Google
          </button>
          <button type="button" className="btn btn-facebook">
            <FontAwesomeIcon icon={faFacebook} /> Register with Facebook
          </button>
        </div>
        <div className="links text-center">
          <p>
            Already have an account? <Link to="/login">Login here</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterComponent;