import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet'; // Import React Helmet
import { Link, useNavigate } from 'react-router-dom';
import { removeFromCart } from '../features/cart/cartSlice';
import { syncCartAndFavourites, deleteFromCart } from '../api/sync';  // Added deleteFromCart import
import './Cart.scss';

const Cart = () => {
  const cartItems = useSelector((state) => state.cart.items);  // Get cart items from Redux store
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');  // Retrieve the userId from localStorage

  // Log userId on component mount to ensure it's available
  useEffect(() => {
    console.log('User ID from localStorage:', userId); // Debugging log
  }, [userId]);

  // Sync the cart when the component loads
  useEffect(() => {
    const syncCart = async () => {
      try {
        await syncCartAndFavourites(userId, dispatch, cartItems);  // Pass current cartItems to sync function
      } catch (error) {
        console.error("Cart sync failed:", error);  // Handle any sync errors
      }
    };

    if (userId) {
      syncCart();  // Call the async function within useEffect
    }
  }, [userId, dispatch, cartItems]);  // Include userId, dispatch, and cartItems as dependencies

  // Handle remove item from cart (includes syncing with server)
  const handleRemoveFromCart = async (product) => {
    const userId = localStorage.getItem('userId');
    
    // Check if userId exists before attempting to delete from the server
    if (!userId) {
      console.error("User ID not found. Please log in to modify the cart.");
      return;
    }
    
    try {
      console.log("Remove button clicked for:", product);  // Debug log

      // Call the backend to remove the item from the server cart first
      await deleteFromCart(userId, product.id, dispatch);
      console.log("Successfully removed item from server cart:", product.id);

      // Remove from Redux store after successful server deletion
      dispatch(removeFromCart(product));
    } catch (error) {
      console.error('Failed to remove item from cart:', error);
    }
  };

  const handleProceedToCheckout = () => {
    navigate('/checkout');  // Navigate to the checkout page
  };

  return (
    <div className="cart-page">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Your Cart | Ruoth</title>
        <meta
          name="description"
          content="Review the items in your cart before proceeding to checkout on Ruoth."
        />
        <meta
          name="keywords"
          content="Ruoth cart, shopping cart, review items, checkout"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Your Cart | Ruoth" />
        <meta
          property="og:description"
          content="View and manage the items in your Ruoth shopping cart."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/assets/images/cart-banner.jpg"
        />
        <meta property="og:url" content="https://www.ruothstore.com/cart" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Your Cart | Ruoth" />
        <meta
          name="twitter:description"
          content="Check out the items in your Ruoth cart before completing your purchase."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/assets/images/cart-banner.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.ruothstore.com/cart" />
      </Helmet>

      <h1>Your Cart</h1>
      {cartItems.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="cart-items">
          {cartItems.map((product, index) => (
            <div key={`${product.id}-${index}`} className="cart-item">  {/* Ensure unique keys by combining id and index */}
              <Link to={`/product/${product.id}`}>
                <img src={product.thumbnail_image} alt={product.name} />
              </Link>
              <div className="cart-item-info">
                <Link to={`/product/${product.id}`}>
                  <h2>{product.name}</h2>
                </Link>
                <p>Price: £{product.price}</p>
                <button onClick={() => handleRemoveFromCart(product)}>Remove</button>
              </div>
            </div>
          ))}
        </div>
      )}
      {cartItems.length > 0 && (
        <button className="checkout-button" onClick={handleProceedToCheckout}>
          Proceed to Checkout
        </button>
      )}
    </div>
  );
};

export default Cart;