import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import './ProductImageGallery.scss';

const ProductImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [isOpen, setIsOpen] = useState(false);

  const slides = images.map(image => ({ src: image }));

  return (
    <div className="product-image-gallery">
      <div className="main-image" onClick={() => setIsOpen(true)}>
        <img src={selectedImage} alt="Selected Product" />
      </div>
      <div className="thumbnail-images">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            className={selectedImage === image ? 'active' : ''}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>
      {isOpen && (
        <Lightbox
          slides={slides}
          open={isOpen}
          close={() => setIsOpen(false)}
          onSlideChange={(current) => setSelectedImage(images[current])}
        />
      )}
    </div>
  );
};

export default ProductImageGallery;