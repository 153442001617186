// src/components/homePage/PromotionSlider.js
import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { axiosInstance } from '../../api/axiosConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PromotionSlider.scss';

const PromotionSlider = () => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchSlides = async () => {
      const response = await axiosInstance.get('/hero-slides/hero-slides/');
      setSlides(response.data);
    };
    fetchSlides();
  }, []);

  return (
    <Carousel className="promotion-slider">
      {slides.map((slide, index) => (
        <Carousel.Item key={index}>
          <img className="d-block w-100" src={slide.image} alt={slide.text} />
          <Carousel.Caption>
            <h3>{slide.text}</h3>
            <a className="btn" href={slide.cta}>Learn More</a>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default PromotionSlider;