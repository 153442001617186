import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../api/axiosConfig'; // Correct import
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import React Helmet
import 'bootstrap/dist/css/bootstrap.min.css';
import './MembershipPage.css';

const MembershipPage = () => {
  const [memberships, setMemberships] = useState([]);
  const [userMembership, setUserMembership] = useState(null);
  const [error, setError] = useState('');
  const { userInfo, token } = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMemberships = async () => {
      try {
        const response = await axiosInstance.get('/accounts/memberships/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMemberships(response.data);
      } catch (error) {
        console.error('There was an error fetching the memberships!', error);
        setError('There was an error fetching the memberships!');
      }
    };

    const fetchUserMembership = async () => {
      try {
        const response = await axiosInstance.get('/accounts/user-memberships/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.length > 0) {
          setUserMembership(response.data[0]);
        }
      } catch (error) {
        console.error('There was an error fetching the user membership!', error);
      }
    };

    if (userInfo && token) {
      fetchMemberships();
      fetchUserMembership();
    } else {
      setError('You need to log in to view memberships.');
      navigate('/login');
    }
  }, [userInfo, token, navigate]);

  const handleRegister = async (membershipId) => {
    if (!userInfo || !token) {
      setError('You need to log in to register for a membership.');
      navigate('/login');
      return;
    }

    const payload = {
      user: userInfo.id, // Ensure this is the user ID
      membership: membershipId,
      start_date: new Date().toISOString().split('T')[0], // Assuming start_date is required
      end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        .toISOString()
        .split('T')[0], // Assuming end_date is required
    };

    try {
      const response = await axiosInstance.post('/accounts/user-memberships/', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Membership registered successfully!');
      setUserMembership(response.data); // Update user membership state
      window.location.reload(); // Reload the page to fetch the updated user membership
    } catch (error) {
      if (error.response && error.response.data && error.response.data.user) {
        setError('You already have a membership.');
      } else {
        console.error(
          'There was an error registering the membership!',
          error.response ? error.response.data : error.message
        );
        setError('There was an error registering the membership!');
      }
    }
  };

  return (
    <div className="membership-page container text-center">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Membership Plans | Ruoth</title>
        <meta
          name="description"
          content="Explore Ruoth's membership plans to enjoy exclusive benefits and offers."
        />
        <meta
          name="keywords"
          content="Ruoth membership, membership plans, exclusive offers, benefits"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Membership Plans | Ruoth" />
        <meta
          property="og:description"
          content="Join Ruoth's membership to access exclusive benefits and offers."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/assets/images/membership-banner.jpg"
        />
        <meta property="og:url" content="https://www.ruothstore.com/membership" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Membership Plans | Ruoth" />
        <meta
          name="twitter:description"
          content="Sign up for Ruoth's membership and enjoy exclusive perks."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/assets/images/membership-banner.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.ruothstore.com/membership" />
      </Helmet>

      <h1>Membership Plans</h1>
      {error && <p className="error-message text-danger">{error}</p>}
      {userMembership ? (
        <div className="user-membership card mx-auto p-4">
          <h2>Your Membership</h2>
          <p>
            <strong>Membership:</strong> {userMembership.membership.name}
          </p>
          <p>
            <strong>Start Date:</strong> {userMembership.start_date}
          </p>
          <p>
            <strong>End Date:</strong> {userMembership.end_date}
          </p>
          <p>
            <strong>Benefits:</strong> {userMembership.membership.benefits}
          </p>
        </div>
      ) : (
        <div className="row justify-content-center">
          {memberships.map((membership) => (
            <div key={membership.id} className="membership-card card col-md-3 m-3">
              <div className="card-body">
                <h2 className="card-title">{membership.name}</h2>
                <p className="card-text">{membership.description}</p>
                <p className="card-text">Price: ${membership.price}</p>
                <p className="card-text">Duration: {membership.duration_months} months</p>
                <p className="card-text">Benefits: {membership.benefits}</p>
                <button
                  className="btn btn-primary"
                  onClick={() => handleRegister(membership.id)}
                >
                  Register
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MembershipPage;