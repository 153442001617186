import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Homepage from './pages/Homepage';
import ProductCatalogue from './pages/ProductCatalogue';
import ProductDetailPage from './pages/ProductDetailPage';
import Checkout from './pages/Checkout';
import UserDashboard from './pages/UserDashboard';
import ContentHubPage from './pages/ContentHubPage';
import ContentDetail from './pages/ContentDetail';  // Use unified ContentDetail component
import CustomerSupport from './pages/CustomerSupport';
import RegisterComponent from './pages/RegisterComponent';
import LoginComponent from './pages/LoginComponent';
import Cart from './pages/Cart';
import Favourites from './pages/Favourites';
import MembershipPage from './pages/MembershipPage';
import ReturnForm from './components/userAccountDashboard/ReturnForm';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import './styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { syncCartAndFavourites } from './api/sync';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { fetchUser, logoutUser } from './features/user/userSlice';
import { jwtDecode } from 'jwt-decode';  // Corrected named import

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const { userInfo } = useSelector(state => state.user);

  // Function to check if the token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = jwtDecode(token);  // Use jwtDecode as named import
    const currentTime = Date.now() / 1000;  // In seconds
    return decodedToken.exp < currentTime;
  };

  useEffect(() => {
    if (token) {
      if (isTokenExpired(token)) {
        dispatch(logoutUser());
      } else if (!userInfo) {
        dispatch(fetchUser());
      }
    }
  }, [token, userInfo, dispatch]);

  useEffect(() => {
    if (userInfo) {
      syncCartAndFavourites(userInfo.id, dispatch);
    }
  }, [userInfo, dispatch]);

  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/catalogue" element={<ProductCatalogue />} />
            <Route path="/product/:id" element={<ProductDetailPage />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/favourites" element={<Favourites />} />
            <Route
              path="/dashboard/*"
              element={
                <ProtectedRoute>
                  <UserDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-dashboard/return/:orderId"
              element={
                <ProtectedRoute>
                  <ReturnForm />
                </ProtectedRoute>
              }
            />
            <Route path="/content-hub" element={<ContentHubPage />} />
            {/* Unified ContentDetail route for all content types */}
            <Route path="/content/:type/:id" element={<ContentDetail />} />
            <Route path="/customer-support" element={<CustomerSupport />} />
            <Route path="/register" element={<RegisterComponent />} />
            <Route path="/login" element={<LoginComponent />} />
            <Route
              path="/support-tickets"
              element={
                <ProtectedRoute>
                  <CustomerSupport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/memberships"
              element={
                <ProtectedRoute>
                  <MembershipPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </Elements>
  );
}

export default App;
