import { axiosInstance } from '../api/axiosConfig';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet'; // Import React Helmet

import ProductImageGallery from '../components/productDetail/ProductImageGallery';
import RelatedProducts from '../components/productDetail/RelatedProducts';
import { addToCart, removeFromCart } from '../features/cart/cartSlice';
import { addToFavourites, removeFromFavourites } from '../features/favourites/favouritesSlice';
import { Nav, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faStarHalfAlt,
  faStar as farStar,
} from '@fortawesome/free-solid-svg-icons';
import sanitizeHtml from 'sanitize-html';
import './ProductDetailPage.scss';

const ProductDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const favouriteItems = useSelector((state) => state.favourites.items);
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [isFavourited, setIsFavourited] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [loading, setLoading] = useState(true);

  // Fetch product details and related products
  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      try {
        const productResponse = await axiosInstance.get(`/products/products/${id}/`);
        setProduct(productResponse.data);

        const relatedResponse = await axiosInstance.get(
          `/products/products/${id}/related/`
        );
        setRelatedProducts(relatedResponse.data);

        // Scroll to top when a new product is loaded
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch (error) {
        console.error('Error fetching product details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    setIsFavourited(favouriteItems.some((item) => item.id === parseInt(id)));
    setIsInCart(cartItems.some((item) => item.id === parseInt(id)));
  }, [favouriteItems, cartItems, id]);

  const handleAddToCart = () => {
    if (isInCart) {
      dispatch(removeFromCart(product));
    } else {
      dispatch(addToCart(product));
    }
    setIsInCart(!isInCart);
  };

  const handleAddToFavourites = () => {
    if (isFavourited) {
      dispatch(removeFromFavourites(product));
    } else {
      dispatch(addToFavourites(product));
    }
    setIsFavourited(!isFavourited);
  };

  // Handle Related Product Click
  const handleRelatedProductClick = (productId) => {
    if (parseInt(productId) !== parseInt(id)) {
      navigate(`/product/${productId}`);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <FontAwesomeIcon key={index} icon={faStar} className="star-icon" />
          ))}
        {halfStar && <FontAwesomeIcon icon={faStarHalfAlt} className="star-icon" />}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <FontAwesomeIcon
              key={index + fullStars + 1}
              icon={farStar}
              className="star-icon"
            />
          ))}
      </>
    );
  };

  const sanitizedDescription = product
    ? sanitizeHtml(product.description, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          'img',
          'h1',
          'h2',
          'h3',
          'p',
          'ul',
          'ol',
          'li',
          'strong',
          'em',
          'a',
          'table',
          'tr',
          'th',
          'td',
          'thead',
          'tbody',
          'iframe',
        ]),
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          img: ['src', 'alt', 'width', 'height', 'style'],
          a: ['href', 'target'],
          iframe: [
            'src',
            'width',
            'height',
            'frameborder',
            'allow',
            'allowfullscreen',
          ],
          '*': ['style', 'class', 'id'],
        },
      })
    : '';

  if (loading) return <div>Be still...</div>;

  return (
    <div className="product-detail-page">
      {/* Meta Tags for SEO */}
      {product && (
        <Helmet>
          <title>{`${product.name} | Ruoth`}</title>
          <meta
            name="description"
            content={
              product.short_description || product.description || 'Product details and specifications.'
            }
          />
          <meta
            name="keywords"
            content={`Ruoth, ${product.name}, ${product.category_name}, ${product.brand}, ${product.product_type}`}
          />
          {/* Open Graph Meta Tags */}
          <meta property="og:title" content={`${product.name} | Ruoth`} />
          <meta
            property="og:description"
            content={
              product.short_description || product.description || 'Product details and specifications.'
            }
          />
          <meta property="og:image" content={product.main_image_url} />
          <meta
            property="og:url"
            content={`https://www.ruothstore.com/product/${product.id}`}
          />
          {/* Twitter Card Meta Tags */}
          <meta name="twitter:title" content={`${product.name} | Ruoth`} />
          <meta
            name="twitter:description"
            content={
              product.short_description || product.description || 'Product details and specifications.'
            }
          />
          <meta name="twitter:image" content={product.main_image_url} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Canonical Link */}
          <link
            rel="canonical"
            href={`https://www.ruothstore.com/product/${product.id}`}
          />
        </Helmet>
      )}

      <div className="product-main-section">
        <div className="product-image-gallery">
          <ProductImageGallery images={product.gallery_images} />
        </div>
        <div className="product-info">
          <h1>{product.name}</h1>
          <p className="product-price">Price: £{product.price}</p>
          <div className="reviews-summary">
            {renderStars(product.average_rating)} ({product.review_count} reviews)
          </div>
          <div className="product-buttons">
            <button onClick={handleAddToCart}>
              {isInCart ? 'Remove from Cart' : 'Add to Cart'}
            </button>
            <button onClick={handleAddToFavourites}>
              {isFavourited ? 'Remove from Favourites' : 'Add to Favourites'}
            </button>
            <button onClick={() => alert('Buy Now functionality not implemented yet')}>
              Buy Now
            </button>
          </div>
          <Tab.Container defaultActiveKey="description">
            <Nav variant="tabs" className="product-tabs">
              <Nav.Item>
                <Nav.Link eventKey="description">Description</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="specs">Specifications</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="reviews">Reviews</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="description">
                <div className="product-description">
                  <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="specs">
                <div className="product-specs">
                  <div>
                    <strong>Brand:</strong> {product.brand}
                  </div>
                  <div>
                    <strong>Eco-friendly:</strong> {product.eco_friendly ? 'Yes' : 'No'}
                  </div>
                  <div>
                    <strong>Stock:</strong> {product.stock}
                  </div>
                  <div>
                    <strong>Category:</strong> {product.category_name}
                  </div>
                  <div>
                    <strong>Highlight:</strong> {product.highlight ? 'Yes' : 'No'}
                  </div>
                  <div>
                    <strong>Product Type:</strong> {product.product_type}
                  </div>
                  <div>
                    <strong>Color:</strong> {product.color}
                  </div>
                  <div>
                    <strong>Dimensions:</strong> {product.dimensions}
                  </div>
                  {product.weight && (
                    <div>
                      <strong>Weight:</strong> {product.weight} kg
                    </div>
                  )}
                  {product.material && (
                    <div>
                      <strong>Material:</strong> {product.material}
                    </div>
                  )}
                  {product.license_key && (
                    <div>
                      <strong>License Key:</strong> {product.license_key}
                    </div>
                  )}
                  {product.video_demo && (
                    <div>
                      <strong>Video Demo:</strong>{' '}
                      <a href={product.video_demo} target="_blank" rel="noopener noreferrer">
                        Watch here
                      </a>
                    </div>
                  )}
                  {product.file_url && (
                    <div>
                      <strong>File URL:</strong>{' '}
                      <a href={product.file_url} target="_blank" rel="noopener noreferrer">
                        Download here
                      </a>
                    </div>
                  )}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="reviews">
                <div className="product-reviews">
                  <h3>Reviews</h3>
                  {product.reviews.length > 0 ? (
                    product.reviews.map((review, index) => (
                      <div key={index} className="review-item">
                        <strong>{review.user_display}</strong>: {review.comment}
                        <div className="review-rating">{renderStars(review.rating)}</div>
                      </div>
                    ))
                  ) : (
                    <p>No reviews yet</p>
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <div className="related-products">
        <RelatedProducts
          products={relatedProducts}
          onProductClick={handleRelatedProductClick}
        />
      </div>
    </div>
  );
};

export default ProductDetailPage;