import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './RelatedProducts.scss';

const RelatedProducts = ({ products }) => {
  const navigate = useNavigate();
  const carouselRef = useRef(null);

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const scrollLeft = () => {
    carouselRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    carouselRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  return (
    <div className="related-products">
      <h2>Related Products</h2>
      <div className="carousel-buttons">
        <button onClick={scrollLeft}>&lt;</button>
        <button onClick={scrollRight}>&gt;</button>
      </div>
      <div className="related-products-carousel" ref={carouselRef}>
        {products.map((product) => (
          <div
            key={product.id}
            className="related-product-item"
            onClick={() => handleProductClick(product.id)}
          >
            <img src={product.thumbnail_image} alt={product.name} />
            <h3>{product.name}</h3>
            <p>£{product.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedProducts;