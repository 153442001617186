import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders, initiateReturn } from '../../features/user/orderSlice';
import 'bootstrap/dist/css/bootstrap.min.css';
import './OrderHistory.scss';
import { useNavigate } from 'react-router-dom';

const OrderHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const orders = useSelector((state) => state.order.orders);
    const orderStatus = useSelector((state) => state.order.status);
    const orderError = useSelector((state) => state.order.error);
    const userId = useSelector((state) => state.user.userInfo?.id);

    useEffect(() => {
        if (userId) {
            dispatch(fetchOrders(userId));
        }
    }, [dispatch, userId]);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
        }).format(value);
    };

    const getStatusBadge = (status) => {
        switch (status) {
            case 'pending':
                return <span className="badge bg-warning text-dark">Pending</span>;
            case 'processing':
                return <span className="badge bg-info text-dark">Processing</span>;
            case 'shipped':
                return <span className="badge bg-primary">Shipped</span>;
            case 'delivered':
                return <span className="badge bg-success">Delivered</span>;
            default:
                return <span className="badge bg-secondary">Unknown</span>;
        }
    };

    const sortedOrders = [...orders].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const handleReturn = (orderId, reason) => {
        dispatch(initiateReturn({ orderId, reason }));
    };

    const handleShowMoreDetails = (orderId) => {
        navigate(`/order/${orderId}`);
    };

    return (
        <div className="order-history section">
            <h2>Order History</h2>
            {orderStatus === 'loading' && <div className="alert alert-info">Loading...</div>}
            {orderStatus === 'failed' && <div className="alert alert-danger">Error loading orders: {orderError}</div>}
            <div className="order-list">
                {sortedOrders.map((order) => (
                    <div key={order.id} className="order-item card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">Order #{order.id}</h5>
                            {getStatusBadge(order.status)}
                        </div>
                        <div className="card-body">
                            <p><strong>Total Price:</strong> {formatCurrency(order.total_price)}</p>
                            {order.tracking_number && (
                                <>
                                    <p><strong>Tracking Number:</strong> {order.tracking_number}</p>
                                    <p><a href={`https://tracking-url.com/${order.tracking_number}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary">Track Order</a></p>
                                </>
                            )}
                            {order.items.some(item => item.is_digital) && (
                                <>
                                    <h6>Digital Items:</h6>
                                    <ul className="list-group mb-3">
                                        {order.items.filter(item => item.is_digital).map(item => (
                                            <li key={item.id} className="list-group-item digital-item">
                                                <p><strong>Product:</strong> {item.product.name}</p>
                                                <p><strong>Download Link:</strong> <a href={item.download_link} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-secondary">Download</a></p>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {order.items.some(item => !item.is_digital) && (
                                <>
                                    <h6>Physical Items:</h6>
                                    <ul className="list-group mb-3">
                                        {order.items.filter(item => !item.is_digital).map(item => (
                                            <li key={item.id} className="list-group-item physical-item">
                                                <p><strong>Product:</strong> {item.product.name}</p>
                                                <p><strong>Quantity:</strong> {item.quantity}</p>
                                                <p><strong>Price:</strong> {formatCurrency(item.price)}</p>
                                                <p><strong>Total Price:</strong> {formatCurrency(item.total_price)}</p>
                                                <button onClick={() => handleReturn(order.id, 'Reason for return')} className="btn btn-sm btn-outline-danger" disabled={item.return_requested}>
                                                    {item.return_requested ? "Return Requested" : "Return"}
                                                    </button>
                                                {item.return_requested && (
                                                    <p><strong>Return Status:</strong> {item.return_status || 'Pending'}</p>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <button onClick={() => handleShowMoreDetails(order.id)} className="btn btn-primary">Show More Details</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrderHistory;