import React from 'react';
import { Helmet } from 'react-helmet';  // Import React Helmet
import HeroSlider from '../components/homePage/PromotionSlider';
import ProductHighlights from '../components/homePage/ProductHighlights';
import Testimonials from '../components/homePage/Testimonials';
import NewsletterSignup from '../components/homePage/NewsletterSignup';
import ContentHubPreview from '../components/homePage/ContentHubPreview';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Homepage.scss';

const Homepage = () => {
  return (
    <div className="homepage">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Ruoth - Empowering Culinary Creativity</title>
        <meta
          name="description"
          content="Ruoth empowers culinary professionals and enthusiasts with high-quality physical and digital tools that enhance kitchen creativity, precision, and efficiency."
        />
        <meta
          name="keywords"
          content="Ruoth, culinary tools, professional kitchen tools, digital culinary solutions, cake scrapers, sieves, culinary creativity, kitchen innovation, sustainable kitchenware"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Ruoth - Empowering Culinary Creativity" />
        <meta
          property="og:description"
          content="Discover Ruoth’s premium physical and digital tools designed to elevate your culinary craft."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/logo192.png"
        />
        <meta property="og:url" content="https://www.ruothstore.com/" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Ruoth - Empowering Culinary Creativity" />
        <meta
          name="twitter:description"
          content="Explore Ruoth’s innovative kitchen tools and digital solutions for culinary professionals."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/logo192.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.ruothstore.com/" />
        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Ruoth",
            "url": "https://www.ruothstore.com/",
            "logo": "https://www.ruothstore.com/logo192.png",
            "sameAs": [
              "https://www.instagram.com/ruothstore/",
              "https://www.facebook.com/theruothstore",
              "https://www.tiktok.com/@ruoth_store",
              "https://www.amazon.co.uk/stores/RUOTH/page/2D27FE96-504E-40D3-A814-916A5E008D0F",
              "https://www.etsy.com/shop/RuothxStore"
            ],
            "description":
              "Ruoth empowers culinary professionals and enthusiasts with high-quality physical and digital tools that enhance kitchen creativity, precision, and efficiency."
          })}
        </script>
      </Helmet>

      {/* Existing Content */}
      <section className="hero-slider">
        <HeroSlider />
      </section>
      <section className="product-highlights section">
        <div className="container">
          <h2 className="section-title">Featured Products</h2>
          <ProductHighlights />
        </div>
      </section>
      <section className="content-hub-preview section bg-light">
        <div className="container">
          <h2 className="section-title">Latest Articles and Guides</h2>
          <ContentHubPreview />
        </div>
      </section>
      <section className="testimonials section">
        <div className="container">
          <h2 className="section-title">What Our Customers Say</h2>
          <Testimonials />
        </div>
      </section>
      <section className="newsletter-signup section bg-light">
        <div className="container">
          <h2 className="section-title">Stay Updated</h2>
          <NewsletterSignup />
        </div>
      </section>
      <section className="customer-support-preview section bg-light">
        <div className="container">
          <h2 className="section-title">Need Help?</h2>
          <p>
            Visit our customer support section for FAQs and live chat support.
          </p>
          <a href="/customer-support" className="btn">
            Go to Customer Support
          </a>
        </div>
      </section>
    </div>
  );
};

export default Homepage;