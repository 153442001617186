import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import React Helmet
import { Container, Row, Col } from 'react-bootstrap';
import SearchComponent from '../components/contentHub/SearchComponent';
import FeaturedSection from '../components/contentHub/FeaturedSection';
import CategorySection from '../components/contentHub/CategorySection';
import Sidebar from '../components/contentHub/Sidebar';
import SpotlightSection from '../components/contentHub/SpotlightSection';
import FooterNavigation from '../components/contentHub/FooterNavigation';
import {
  fetchArticles,
  fetchGuides,
  fetchVideos,
  fetchWhitePapers,
  fetchCaseStudies,
  fetchEbooks,
  fetchResearchReports,
  fetchThoughtLeadership,
  fetchTechnicalManuals,
  fetchNewsletters,
  fetchPresentations,
  fetchPressReleases,
} from '../api/contentApi';
import './ContentHubPage.scss';

const ContentHubPage = () => {
  const [contentData, setContentData] = useState({
    articles: [],
    guides: [],
    videos: [],
    whitePapers: [],
    caseStudies: [],
    ebooks: [],
    researchReports: [],
    thoughtLeadership: [],
    technicalManuals: [],
    newsletters: [],
    presentations: [],
    pressReleases: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getContent = async () => {
      try {
        const [
          articleData,
          guideData,
          videoData,
          whitePaperData,
          caseStudyData,
          ebookData,
          researchReportData,
          thoughtLeadershipData,
          technicalManualData,
          newsletterData,
          presentationData,
          pressReleaseData,
        ] = await Promise.all([
          fetchArticles(),
          fetchGuides(),
          fetchVideos(),
          fetchWhitePapers(),
          fetchCaseStudies(),
          fetchEbooks(),
          fetchResearchReports(),
          fetchThoughtLeadership(),
          fetchTechnicalManuals(),
          fetchNewsletters(),
          fetchPresentations(),
          fetchPressReleases(),
        ]);

        setContentData({
          articles: articleData.data,
          guides: guideData.data,
          videos: videoData.data,
          whitePapers: whitePaperData.data,
          caseStudies: caseStudyData.data,
          ebooks: ebookData.data,
          researchReports: researchReportData.data,
          thoughtLeadership: thoughtLeadershipData.data,
          technicalManuals: technicalManualData.data,
          newsletters: newsletterData.data,
          presentations: presentationData.data,
          pressReleases: pressReleaseData.data,
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching content:', error);
        setLoading(false);
      }
    };
    getContent();
  }, []);

  const handleSearchResults = (results) => {
    const updatedContentData = {
      articles: results.filter((item) => item.content_type === 'article'),
      guides: results.filter((item) => item.content_type === 'guide'),
      videos: results.filter((item) => item.content_type === 'video'),
      whitePapers: results.filter((item) => item.content_type === 'white_paper'),
      caseStudies: results.filter((item) => item.content_type === 'case_study'),
      ebooks: results.filter((item) => item.content_type === 'ebook'),
      researchReports: results.filter((item) => item.content_type === 'research_report'),
      thoughtLeadership: results.filter((item) => item.content_type === 'thought_leadership'),
      technicalManuals: results.filter((item) => item.content_type === 'technical_manual'),
      newsletters: results.filter((item) => item.content_type === 'newsletter'),
      presentations: results.filter((item) => item.content_type === 'presentation'),
      pressReleases: results.filter((item) => item.content_type === 'press_release'),
    };
    setContentData(updatedContentData);
  };

  return (
    <Container fluid className="content-hub-page">
      {/* Meta Tags for SEO */}
      <Helmet>
        <title>Content Hub | Ruoth</title>
        <meta
          name="description"
          content="Explore Ruoth's Content Hub for the latest articles, guides, videos, and resources to enhance your culinary creativity and knowledge."
        />
        <meta
          name="keywords"
          content="Ruoth Content Hub, culinary articles, kitchen guides, cooking videos, white papers, case studies, ebooks, research reports"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Content Hub | Ruoth" />
        <meta
          property="og:description"
          content="Discover a wealth of culinary resources in Ruoth's Content Hub, including articles, guides, videos, and more."
        />
        <meta
          property="og:image"
          content="https://www.ruothstore.com/assets/images/content-hub-banner.jpg"
        />
        <meta property="og:url" content="https://www.ruothstore.com/content-hub" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Content Hub | Ruoth" />
        <meta
          name="twitter:description"
          content="Stay updated with Ruoth's latest culinary content, from articles and guides to videos and research."
        />
        <meta
          name="twitter:image"
          content="https://www.ruothstore.com/assets/images/content-hub-banner.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.ruothstore.com/content-hub" />
      </Helmet>

      {/* Hero/Featured Section */}
      <FeaturedSection contents={contentData.articles.slice(0, 3)} />

      {/* Search Component */}
      <SearchComponent onSearch={handleSearchResults} />

      {/* Loading State */}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {/* Main Content Layout */}
          <Row>
            <Col md={8}>
              {/* Category Sections */}
              <CategorySection title="Articles" contents={contentData.articles} />
              <CategorySection title="Guides" contents={contentData.guides} />
              <CategorySection title="Videos" contents={contentData.videos} />
              <CategorySection title="White Papers" contents={contentData.whitePapers} />
              <CategorySection title="Case Studies" contents={contentData.caseStudies} />
              <CategorySection title="Ebooks" contents={contentData.ebooks} />
              <CategorySection title="Research Reports" contents={contentData.researchReports} />
              <CategorySection title="Thought Leadership" contents={contentData.thoughtLeadership} />
              <CategorySection title="Technical Manuals" contents={contentData.technicalManuals} />
              <CategorySection title="Newsletters" contents={contentData.newsletters} />
              <CategorySection title="Presentations" contents={contentData.presentations} />
              <CategorySection title="Press Releases" contents={contentData.pressReleases} />
            </Col>

            {/* Sidebar with additional widgets */}
            <Col md={4}>
              <Sidebar mostRead={contentData.articles.slice(0, 5)} />
            </Col>
          </Row>

          {/* Spotlight Section */}
          <SpotlightSection content={contentData.researchReports[0]} />

          {/* Footer Navigation */}
          <FooterNavigation />
        </>
      )}
    </Container>
  );
};

export default ContentHubPage;