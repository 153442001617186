import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    // Track pageview on route change
    window.gtag('config', 'G-TE55YV50V4', {
      page_path: location.pathname,
    });
  }, [location]);

  return null;
}

export default AnalyticsTracker;