import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ContentCard from './ContentCard';
import './CategorySection.scss';

const CategorySection = ({ title, contents }) => (
  <section className="category-section">
    <h2>{title}</h2>
    <Row>
      {contents.map(content => (
        <Col xs={12} md={6} lg={4} key={content.id}>
          <ContentCard content={content} />
        </Col>
      ))}
    </Row>
  </section>
);

export default CategorySection;
