import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faCartPlus, faStar, faStarHalfAlt, faStar as farStar } from '@fortawesome/free-solid-svg-icons'; // Include star icons
import { addToCart, removeFromCart } from '../../features/cart/cartSlice';
import { addToFavourites, removeFromFavourites } from '../../features/favourites/favouritesSlice';
import './ProductCard.scss';

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cart.items);
  const favouriteItems = useSelector(state => state.favourites.items);
  const [isFavourited, setIsFavourited] = useState(false);
  const [isInCart, setIsInCart] = useState(false);

  useEffect(() => {
    console.log("ProductCard rendering product:", product);
    setIsFavourited(favouriteItems.some(item => item.id === product.id));
    setIsInCart(cartItems.some(item => item.id === product.id));
  }, [favouriteItems, cartItems, product.id]);

  const handleFavouriteClick = () => {
    console.log("Favourite button clicked for product:", product);
    if (isFavourited) {
      dispatch(removeFromFavourites(product));
    } else {
      dispatch(addToFavourites(product));
    }
    setIsFavourited(!isFavourited);
  };

  const handleAddToCartClick = () => {
    console.log("Add to Cart button clicked for product:", product);
    if (isInCart) {
      dispatch(removeFromCart(product));
    } else {
      dispatch(addToCart(product));
    }
    setIsInCart(!isInCart);
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array.from({ length: fullStars }, (_, index) => (
          <FontAwesomeIcon key={`full-${index}`} icon={faStar} className="star-icon" />
        ))}
        {halfStar && <FontAwesomeIcon key="half" icon={faStarHalfAlt} className="star-icon" />}
        {Array.from({ length: emptyStars }, (_, index) => (
          <FontAwesomeIcon key={`empty-${index}`} icon={farStar} className="star-icon" />
        ))}
      </>
    );
  };

  const renderReviews = () => {
    if (product.review_count > 0) {
      return (
        <p className="reviews">
          {renderStars(product.average_rating)} ({product.review_count} reviews)
        </p>
      );
    }
    return <p className="reviews">No reviews yet</p>;
  };

  return (
    <div className="card product-card">
      <div className="card-header">
        <button className="btn btn-link" onClick={handleFavouriteClick}>
          <FontAwesomeIcon icon={faHeart} color={isFavourited ? 'red' : 'gray'} />
        </button>
        <button className="btn btn-link" onClick={handleAddToCartClick}>
          <FontAwesomeIcon icon={faCartPlus} color={isInCart ? 'green' : 'gray'} />
        </button>
      </div>
      <img
        src={product.thumbnail_image}
        className="card-img-top"
        alt={product.alt_text || product.name}
        onError={(e) => (e.target.src = 'fallback/image/path.jpg')}
      />
      <div className="card-body">
        <h5 className="card-title">{product.name}</h5>
        <p className="card-text">Price: £{product.price.toFixed(2)}</p> {/* Ensure price is formatted as currency */}
        {renderReviews()}
        <Link to={`/product/${product.id}`} className="btn btn-primary">View Details</Link>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    thumbnail_image: PropTypes.string,
    alt_text: PropTypes.string,
    average_rating: PropTypes.number,
    review_count: PropTypes.number,
  }).isRequired,
};

export default ProductCard;